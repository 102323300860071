@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap);

.Dashboard_div__1VCB6{
    display: none;
    position: absolute;
    padding: 1rem;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%,-50%);
            transform: translate(-50%,-50%);
    color: white;
    font-weight: bold;
    font-size: 16px;
    background-color: red;
    

}

.Dashboard_container__BH_Ca:hover .Dashboard_div__1VCB6 {
  display: block;
  cursor: pointer;
  background: rgba(0, 0, 0, .3);
  cursor: pointer;
}

.Dashboard_container__BH_Ca:hover{
  position: relative;
  opacity: 0.5;
}
.sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  min-height: 100vh !important;
  z-index: 100;
  padding: 48px 0 0;
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, 0.1);
}
#sidebar-wrapper {
  min-height: 100vh !important;
  width: 100vw;
  margin-left: -1rem;
  transition: margin 0.25s ease-out;
}
#sidebar-wrapper .sidebar-heading {
  padding: 0.875rem 1.25rem;
  font-size: 1.2rem;
}

#page-content-wrapper {
  min-width: 0;
  width: 100%;
}

.form__btn_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.form__btn_container .form_submit_button {
  padding: 0.5rem 1rem;
  background-color: white;
  font-size: 1rem;
  font-weight: 600;
  margin-top: 1rem;
  border-radius: 4px;
  outline: none;
  color: #26c6da;
  cursor: pointer;
  border: 1px solid #26c6da;
  transition: all 400ms ease-in-out;
  margin-right: 1rem;
}

.form__btn_container .form_submit_button:hover {
  background-color: #26c6da;
  color: white;
}

.form__btn_container .cancel {
  color: #c60c30;
  border: 1px solid #c60c30;
  margin-right: 0;
}

.form__btn_container .cancel:hover {
  background-color: #c60c30;
  color: white;
}

.confirm__delete {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  background: transparent;
  background-color: unset;
}

.confirm__delete .confirm {
  background-color: white;
  padding: 1rem 0;
  box-shadow: 0px 2px 5px #424242;
  border-radius: 5px;
  width: 40%;
}

.confirm__delete .confirm .header {
  padding: 0.5rem 1.2rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.75);
  margin-bottom: 1rem;
}

.confirm__delete .confirm .header h2 {
  font-weight: 400;
}

.confirm__delete .confirm .form__btn_container {
  padding: 0 1.2rem;
}

.file_input_button {
  padding: 0.5rem 0.85rem;
  border-radius: 5px;
  background-color: white;
  color: #26c6da;
  border: 1px solid #26c6da;
  font-size: 1rem;
  transition: all 400ms ease-in-out;
  outline: none;
}

.file_input_button:hover {
  background-color: #26c6da;
  color: white;
}

.success_line {
  font-size: 0.8rem;
  margin-bottom: 0.5rem;
  color: green;
}

.card {
  border: 1px solid lightgray;
  width: 31%;
  margin: 0.5rem;
  border-radius: 5px;
  box-shadow: 0 2px 6px 2px rgba(221, 221, 221, 0.75);
  transition: all 300ms ease-in-out;
}

@media (max-width: 560px) {
  .card {
    min-width: 94%;
    flex: 1 1;
  }
}

.home-page {
  padding-bottom: 3rem;
}

.home-page .container_grids {
  width: -webkit-max-content;
  width: max-content;
  margin: auto;
}

@media (min-width: 320px) and (max-width: 768px) {
  .home-page .container_grids {
    width: unset;
  }
}

.home-page .container_grids .notfication-bar {
  grid-template-rows: [row] 1fr;
  grid-template-columns: [viewport-start] 1fr [content-start] minmax(0, 1248px) [content-end] 1fr [viewport-end];
  display: grid;
  grid-gap: 24px;
  margin-top: 3rem;
}

@media (min-width: 320px) and (max-width: 768px) {
  .home-page .container_grids .notfication-bar {
    grid-gap: 12px;
  }
}

.home-page .container_grids .notfication-bar .inner_grid {
  grid-gap: 0;
  grid-template: [row] 1fr / repeat(12, [col] minmax(0, 110px));
  grid-column: content-start/content-end;
  display: grid;
  background-color: white;
  padding: 0.5rem 1rem;
}

.home-page .container_grids .notfication-bar .inner_grid .content {
  width: auto;
  grid-column: span 12;
  display: flex;
  min-height: 1px;
  align-items: baseline;
}

.home-page .container_grids .notfication-bar .inner_grid .content h3 {
  padding: 0.5rem;
}

@media screen and (max-width: 560px) {
  .home-page .container_grids .notfication-bar .inner_grid .content h3 {
    font-size: 14px;
  }
}

.home-page .container_grids .notfication-bar .inner_grid .content h4 {
  padding: 0.5rem;
  font-weight: 300;
}

@media screen and (max-width: 560px) {
  .home-page .container_grids .notfication-bar .inner_grid .content h4 {
    display: none;
  }
}

.home-page .container_grids .notfication-bar .inner_grid .content span {
  background-color: #c60c30;
  padding: 0.5rem;
  color: white;
  font-weight: 600;
  padding-top: 0.1rem;
  cursor: pointer;
}

.home-page .container_grids .notfication-bar .inner_grid .show_details {
  color: #c60c30;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: auto;
}

.home-page .container_grids .notfication-bar .inner_grid .show_details h4 {
  font-weight: 300;
  cursor: pointer;
}

.home-page .container_grids .notfication-bar .inner_grid .show_details svg {
  margin-left: 0.4rem;
  display: inline-block;
}

.home-page .container_grids .hidden-details {
  width: auto;
  margin: 0 auto;
  background-color: white;
  transition: all 400ms ease-in-out;
  margin-top: 0.2rem;
  margin: 0 24px;
}

@media screen and (max-width: 1366px) {
  .home-page .container_grids .hidden-details {
    width: 94%;
    margin-top: 0.2rem;
  }
}

@media (min-width: 320px) and (max-width: 768px) {
  .home-page .container_grids .hidden-details {
    margin: 0 12px;
  }
}

.home-page .container_grids .hidden-details .header {
  padding: 1.4rem 2.5% 0.2rem;
  width: 100%;
  border-top: 1px solid #424242;
  border-bottom: 1px solid #424242;
}

.home-page .container_grids .hidden-details .header h3 {
  text-transform: uppercase;
}

.home-page .container_grids .hidden-details .header {
  border-bottom: 1px solid #ccc;
}

.home-page .container_grids .hidden-details .header h3 {
  font-size: 1.2rem;
  color: #757575;
  text-transform: uppercase;
  font-weight: 500;
}

.home-page .container_grids .hidden-details .items {
  padding: 1rem 1.2rem;
  background-color: white;
  border-bottom: 1px solid #424242;
}

.home-page .container_grids .hidden-details .items:nth-child(odd) {
  background-color: rgba(228, 228, 228, 0.45);
}

.home-page .container_grids .hidden-details .items .date-time {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.home-page .container_grids .hidden-details .items .date-time h5 {
  color: #757575;
  font-size: 12px;
  font-weight: 300;
}

.home-page .container_grids .hidden-details .items .title {
  display: flex;
  flex-direction: row;
  margin: 0.5rem 0 1rem;
}

.home-page .container_grids .hidden-details .items .title span {
  background-color: #c60c30;
  padding: 0.5rem;
  color: white;
  font-weight: 600;
  padding-top: 0.1rem;
  cursor: pointer;
}

.home-page .container_grids .hidden-details .items .title h3 {
  margin-left: 1rem;
  font-size: 16px;
}

.home-page .container_grids .hidden-details .items .body {
  font-size: 14px;
  color: #757575;
  line-height: 1.4;
}

.home-page .home-header {
  margin-top: 40px;
  display: grid;
  grid-gap: 24px;
  grid-template-rows: [row] 1fr;
  grid-template-columns: [viewport-start] 1fr [content-start] minmax(0, 1248px) [content-end] 1fr [viewport-end];
}

@media (min-width: 320px) and (max-width: 767px) {
  .home-page .home-header {
    grid-template-rows: [row] 1fr;
    grid-template-columns:
      [viewport-start] 1fr [content-start] minmax(0, 1248px)
      [content-end] 1fr [viewport-end];
    grid-gap: 12px;
  }
}

.home-page .home-header .inner_grid {
  display: grid;
  grid-column: content-start/content-end;
  grid-template: [row] 1fr / repeat(12, [col] minmax(0, 82px));
  grid-gap: 24px;
}

.home-page .home-header .inner_grid .carousal-display {
  border-radius: 5px;
  box-shadow: 0 2px 5px 0 grey;
  background-color: white;
  width: auto;
  grid-column: span 8;
  align-items: stretch;
  min-height: 1px;
  transition: all 200ms ease-in-out;
}

@media (min-width: 320px) and (max-width: 768px) {
  .home-page .home-header .inner_grid .carousal-display {
    width: auto;
    grid-column: span 12;
  }
}

.home-page
  .home-header
  .inner_grid
  .carousal-display
  .carousel-container
  .carousel-item
  .carousel-image-container {
  max-height: 400px;
  overflow: hidden;
  position: relative;
  height: 400px;
  background-position: center;
  background-repeat: no-repeat;
  background-clip: border-box;
  background-blend-mode: exclusion;
  background-size: cover;
}

.home-page
  .home-header
  .inner_grid
  .carousal-display
  .carousel-container
  .carousel-item
  .carousel-image-container
  .blurred {
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 10;
  background: transparent rgba(17, 17, 17, 0.3);
}

.home-page
  .home-header
  .inner_grid
  .carousal-display
  .carousel-container
  .carousel-item
  .carousel-details {
  padding: 1rem 3.5%;
}

.home-page
  .home-header
  .inner_grid
  .carousal-display
  .carousel-container
  .carousel-item
  .carousel-details
  h1 {
  padding: 16px 0;
  font-weight: 700;
  line-height: 1.13;
  text-align: left;
  color: #333;
  font-size: calc(32px + (100vw - 1024px) / 256 * 0);
}

@media screen and (max-width: 560px) {
  .home-page
    .home-header
    .inner_grid
    .carousal-display
    .carousel-container
    .carousel-item
    .carousel-details
    h1 {
    font-size: 20px;
  }
}

.home-page
  .home-header
  .inner_grid
  .carousal-display
  .carousel-container
  .carousel-item
  .carousel-details
  p {
  line-height: 1.71;
  text-align: left;
  color: #757575;
  word-break: break-word;
  font-size: calc(14px + (100vw - 768px) / 256 * 0);
}

.home-page
  .home-header
  .inner_grid
  .carousal-display
  .carousel-container
  .carousel-action-indicators {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 1rem 0;
}

.home-page
  .home-header
  .inner_grid
  .carousal-display
  .carousel-container
  .carousel-action-indicators
  .left-arrow-action,
.home-page
  .home-header
  .inner_grid
  .carousal-display
  .carousel-container
  .carousel-action-indicators
  .right-arrow-indicators {
  flex: 0.055 1;
  padding: 1rem 0.2rem;
  cursor: pointer;
}

.home-page
  .home-header
  .inner_grid
  .carousal-display
  .carousel-container
  .carousel-action-indicators
  .right-arrow-indicators {
  text-align: right;
}

.home-page
  .home-header
  .inner_grid
  .carousal-display
  .carousel-container
  .carousel-dots-container {
  width: 10%;
  margin: 1rem auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

@media screen and (max-width: 560px) {
  .home-page
    .home-header
    .inner_grid
    .carousal-display
    .carousel-container
    .carousel-dots-container {
    width: 20%;
  }
}

.home-page
  .home-header
  .inner_grid
  .carousal-display
  .carousel-container
  .carousel-dots-container
  .carousel-dots {
  height: 10px;
  width: 10px;
  background-color: #cecece;
  border-radius: 50%;
}

.home-page
  .home-header
  .inner_grid
  .carousal-display
  .carousel-container
  .carousel-dots-container
  .carousel-dots-active {
  height: 11px;
  width: 11px;
  background-color: #c60c30;
  border-radius: 50%;
}

.home-page .home-header .inner_grid .headline_logo_container {
  width: auto;
  grid-column: span 4;
  display: flex;
  align-items: stretch;
  min-height: 1px;
  position: relative;
}

@media (min-width: 320px) and (max-width: 768px) {
  .home-page .home-header .inner_grid .headline_logo_container {
    width: auto;
    grid-column: span 12;
    margin-top: 2rem;
  }
}

.home-page .home-header .inner_grid .headline_logo_container .headline-stack {
  box-shadow: 0 2px 10px 0 grey;
  background-color: white;
  border-radius: 4px;
  flex-grow: unset;
  width: 100%;
  min-height: 180px;
}

.home-page
  .home-header
  .inner_grid
  .headline_logo_container
  .headline-stack
  .headstack-container
  .headstack-bar {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.home-page
  .home-header
  .inner_grid
  .headline_logo_container
  .headline-stack
  .headstack-container
  .headstack-bar
  .item {
  flex: 1 1;
  text-align: center;
  border-bottom: 1px solid rgba(17, 17, 17, 0.3);
  cursor: pointer;
  padding: 1rem 1.5rem;
}

.home-page
  .home-header
  .inner_grid
  .headline_logo_container
  .headline-stack
  .headstack-container
  .headstack-bar
  .item:hover {
  border: 1px solid #c60c30;
  border-bottom: 3px solid #c60c30;
}

.home-page
  .home-header
  .inner_grid
  .headline_logo_container
  .headline-stack
  .headstack-container
  .headstack-bar
  .item-active {
  color: #c60c30;
  border-bottom: 3px solid #c60c30;
  font-weight: 500;
}

.home-page
  .home-header
  .inner_grid
  .headline_logo_container
  .headline-stack
  .headstack-container
  .headstack-bar
  .item-active:hover {
  border: 1px solid #c60c30;
  border-bottom: 3px solid #c60c30;
}

@media screen and (max-width: 560px) {
  .home-page
    .home-header
    .inner_grid
    .headline_logo_container
    .headline-stack
    .headstack-container
    .headstack-bar
    .item-active {
    border: unset;
    border-bottom: 2px solid #c60c30;
  }
}

.home-page
  .home-header
  .inner_grid
  .headline_logo_container
  .headline-stack
  .headstack-container
  .display-content {
  margin: 2rem 0.5rem;
}

.home-page
  .home-header
  .inner_grid
  .headline_logo_container
  .headline-stack
  .headstack-container
  .display-content
  .content {
  text-align: center;
}

.home-page
  .home-header
  .inner_grid
  .headline_logo_container
  .headline-stack
  .headstack-container
  .display-content
  .content
  .item {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 1rem 0;
  text-align: left;
}

.home-page
  .home-header
  .inner_grid
  .headline_logo_container
  .headline-stack
  .headstack-container
  .display-content
  .content
  .item
  svg {
  flex: 0.1 1;
}

.home-page
  .home-header
  .inner_grid
  .headline_logo_container
  .headline-stack
  .headstack-container
  .display-content
  .content
  .item
  a,
.home-page
  .home-header
  .inner_grid
  .headline_logo_container
  .headline-stack
  .headstack-container
  .display-content
  .content
  .item
  p {
  flex: 0.9 1;
  color: #333;
  cursor: pointer;
  font-size: 14px;
  line-height: 18px;
  text-decoration: none;
  letter-spacing: 0.2px;
  font-weight: 500;
  text-decoration: none;
}

.home-page
  .home-header
  .inner_grid
  .headline_logo_container
  .headline-stack
  .headstack-container
  .display-content
  .content
  .more-content {
  margin-top: 2rem;
  position: absolute;
  bottom: 20px;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}

@media (min-width: 320px) and (max-width: 768px) {
  .home-page
    .home-header
    .inner_grid
    .headline_logo_container
    .headline-stack
    .headstack-container
    .display-content
    .content
    .more-content {
    position: relative;
    margin-top: 2rem;
    -webkit-transform: none;
            transform: none;
    bottom: unset;
    left: unset;
  }
}

.home-page
  .home-header
  .inner_grid
  .headline_logo_container
  .headline-stack
  .headstack-container
  .display-content
  .content
  .more-content
  a {
  color: #c60c30;
  font-weight: 600;
  text-decoration: none;
  font-size: 1rem;
}

.home-page .home-header .inner_grid .headline_logo_container .logo_container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 300px;
  height: 250px;
  background-color: white;
  margin: 3rem auto 0;
}

.home-page
  .home-header
  .inner_grid
  .headline_logo_container
  .logo_container
  img {
  height: 100%;
  width: 100%;
  margin: 0 auto;
}

.resource__player {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 100vh;
  background-color: rgba(255, 255, 255, 0.85);
}

.resource__player .cover {
  background-color: #eee;
  padding: 1rem;
}

.resource__player .cover .close {
  position: absolute;
  top: 4%;
  right: 4%;
  cursor: pointer;
}

.resource__player .cover .resource__container {
  text-align: center;
}

.resource__player .cover .resource__container .details {
  text-align: left;
  margin-bottom: 2.4rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid rgba(85, 84, 85, 0.96);
}

.resource__player .cover .resource__container .details h2 {
  font-size: 1.5rem;
  font-weight: 400;
}

.resource__player .cover .resource__container p {
  margin-top: 0.5rem;
  font-size: 1.2rem;
  font-weight: 300;
  color: #424242;
}

.resource__player .cover .resource__container video {
  height: 500px;
  width: 100%;
  background-color: #757575;
  min-width: 200px;
}

.centre_content {
  height: 100vh;
  width: 70%;
  margin: auto;
  background-color: white;
  padding: 2rem;
}

.centre_content .header {
  text-align: center;
  margin: 1rem auto 3rem;
}

.centre_content .header .header_title {
  padding: 1rem;
}

.centre_content .header .header_title .image {
  cursor: pointer;
  background-color: #676767;
  margin-bottom: 0.5rem;
}

.centre_content .header .header_content h1 {
  font-size: 2rem;
  font-weight: 500;
  color: #171717;
  margin-bottom: 1rem;
}

.centre_content .header .header_content h3 {
  font-size: 1.4rem;
  color: #6c757d;
  font-weight: 300;
}

.centre_content .form_container {
  width: 40%;
  margin: 1rem auto;
}

.centre_content .form_container .form input {
  outline: none;
  padding: 0.5rem 1rem;
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.5);
  width: 100%;
  margin: 1rem 0;
  font-size: 1rem;
  color: #8c8b8c;
}

.centre_content .form_container .form .p_input {
  border-bottom: 1px solid rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 0.5rem;
  margin: 1rem 0;
}

.centre_content .form_container .form .p_input input {
  border-bottom: none;
  margin: unset;
  margin-bottom: 0;
}

.centre_content .form_container .form .p_input svg {
  margin: 0 0.5rem;
}

.centre_content .form_container .form_submit_button {
  padding: 0.65rem 0;
  font-size: 1rem;
  font-weight: 400;
  color: #125a96;
  background-color: #c7e1f7;
  border-radius: 15px;
  border: none;
  outline: none;
  width: 100%;
  cursor: pointer;
  margin-right: 1rem;
}

.centre_content .btn_row_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 40%;
  margin: 1rem auto;
}

.centre_content .btn_row_container .btn_row {
  flex: 0.49 1;
  border-radius: 15px;
  background-color: #e3e6ec;
  text-align: center;
  padding: 0.65rem 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.centre_content .btn_row_container .btn_row .btn {
  background-color: inherit;
  border: none;
  outline: none;
  font-size: 0.95rem;
  font-weight: 600;
  color: #67686a;
  margin-left: 0.3rem;
  cursor: pointer;
}

.centre_content .btn_row_container .btn_row svg {
  cursor: pointer;
}

.centre_content .btn_row_container .sign_in {
  flex: 1 1;
}

.headstack_details {
  padding: 2rem;
}

.headstack_details .header {
  margin: 0 0 2rem;
}

.headstack_details .header h1 {
  font-weight: 500;
  font-size: 2rem;
}

.headstack_details .header h3 {
  font-weight: 300;
  font-size: 1rem;
}

.headstack_details .headstack_form_container {
  background-color: white;
  padding: 0.85rem;
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.2);
}

.headstack_details .headstack_form_container .input {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 0 0 1.5rem;
}

.headstack_details .headstack_form_container .input label {
  margin-bottom: 0.45rem;
}

.headstack_details .headstack_form_container .input input {
  padding: 0.5rem 0.85rem;
  font-size: 1rem;
  border: none;
  border-radius: 3px;
  outline: none;
  background-color: rgba(250, 250, 250, 0.9);
  border: 1px solid rgba(0, 0, 0, 0.2);
  width: 60%;
}

.headstack_details .headstack_form_container .input input:focus {
  border: 2px solid rgba(0, 0, 0, 0.4);
}

.headstack_details .headstack_form_container .input textarea {
  padding: 0.5rem 0.85rem;
  font-size: 1rem;
  border: none;
  border-radius: 3px;
  outline: none;
  background-color: rgba(250, 250, 250, 0.9);
  border: 1px solid rgba(0, 0, 0, 0.2);
  width: 65%;
  min-height: 100px;
}

.headstack_details .headstack_form_container .input textarea:focus {
  border: 2px solid rgba(0, 0, 0, 0.4);
}

.headstack_details .headstack_form_container .input input[type="file"] {
  padding: 0;
  background-color: unset;
  border-radius: unset;
}

.headstack_details .headstack_data_table {
  margin: 2rem auto;
  border: 1px solid rgba(0, 0, 0, 0.2);
}

.headstack_details .headstack_data_table .ejmWVz {
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

.admin_navbar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.admin_navbar .admin_links {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.admin_navbar .admin_links a {
  display: block;
  text-decoration: none;
  color: skyblue;
  padding-left: 0;
  text-align: center;
  padding-bottom: 0.6rem;
}

.admin_navbar .admin_links a:nth-child(1) {
  margin-right: 1rem;
}

.admin_navbar .admin_links .active_admin_link {
  color: #c60c30;
  border-bottom: 3px solid rgba(198, 12, 48, 0.5);
  text-align: center;
}

.admin_navbar .logout button {
  padding: 0.75rem 1.2rem;
  background-color: #c60c30;
  font-size: 1rem;
  color: white;
  font-weight: 600;
  border: none;
  outline: none;
  border-radius: 10px;
  box-shadow: 0px 3px 5px 0px rgba(198, 12, 48, 0.5);
}

.bg-flash {
  background: linear-gradient(135deg, #d262e3 0, #3f9ce8 100%) !important;
}

.footer {
  margin-top: 40px;
  margin-bottom: 3rem;
  display: grid;
  grid-template-rows: [row] 1fr;
  grid-template-columns: [viewport-start] 1fr [content-start] minmax(0, 1248px) [content-end] 1fr [viewport-end];
}

@media (min-width: 1280px) {
  .footer {
    grid-gap: 24px;
  }
}

@media (min-width: 320px) and (max-width: 768px) {
  .footer {
    grid-gap: calc(8px + (100vw - 320px) / 448 * 8);
  }
}

.footer .footer_container {
  display: grid;
  grid-column: content-start/content-end;
  grid-template: [row] 1fr / repeat(12, [col] minmax(0, 82px));
  grid-gap: 24px;
}

@media (min-width: 320px) and (max-width: 768px) {
  .footer .footer_container {
    grid-gap: calc(8px + (100vw - 320px) / 448 * 8);
  }
}

.footer .footer_container .footer_title {
  width: auto;
  grid-column: span 12;
  display: flex;
  align-items: stretch;
  min-height: 1px;
}

.footer .footer_container .footer_title .title {
  border-bottom: 1px solid #ccc;
  width: 100%;
}

.footer .footer_container .footer_title .title h3 {
  min-height: 34px;
  font-weight: 400;
  font-size: 1rem;
  border-bottom: 1px solid #333;
  width: -webkit-max-content;
  width: max-content;
}

.footer .footer_container .d3-l-col__col-4 {
  width: auto;
  grid-column: span 4;
  display: flex;
  align-items: stretch;
  min-height: 1px;
}

@media (max-width: 768px) {
  .footer .footer_container .d3-l-col__col-4 {
    width: auto;
    grid-column: span 12;
  }
}

.footer .footer_container .d3-l-col__col-4 .col-4__platform {
  background-color: #fff;
  align-items: center;
  padding: 8px;
  border: 1px solid transparent;
  display: inline-block;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.footer .footer_container .d3-l-col__col-4 .col-4__platform .image .circle {
  position: relative;
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 3px solid #323232;
  border-radius: 50%;
}

.footer .footer_container .d3-l-col__col-4 .col-4__platform .image .icon {
  height: 20px;
  width: 20px;
  z-index: 0;
}

.footer .footer_container .d3-l-col__col-4 .col-4__platform .detail {
  margin-left: 1.5rem;
  color: #323232;
}

.footer .footer_container .d3-l-col__col-4 .col-4__platform .detail h3 {
  font-size: 1rem;
}

.footer .footer_container .d3-l-col__col-4 .col-4__platform .detail h5 {
  font-weight: 400;
}

.screen {
  display: flex;
  flex-direction: row;
}

.screen .left_sidebar {
  background-color: white;
  min-height: 100vh;
  min-width: 250px;
  max-width: 20%;
  box-shadow: 5px 0px 5px #ccc;
}

.screen .left_sidebar .sidebar__header {
  margin: 1rem auto;
  padding: 0.5rem;
  text-align: center;
  border-bottom: 2px solid rgba(0, 0, 0, 0.4);
}

.screen .left_sidebar .sidebar__header .header__logo {
  background-color: #676767;
  margin-bottom: 1rem;
  border-radius: 5px;
}

.screen .left_sidebar .sidebar__header .header__logo img {
  width: 100%;
  height: 150px;
}

.screen .left_sidebar .sidebar__header .header__title {
  margin-bottom: 1rem;
}

.screen .left_sidebar .sidebar__header .header__title h3 {
  font-size: 1rem;
  font-weight: 400;
  color: #676767;
}

.screen .left_sidebar .sidebar__navlinks {
  padding: 0.5rem 0.5rem 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}

.screen .left_sidebar .sidebar__navlinks .links {
  margin: 0.5rem;
  padding: 0.5rem;
  cursor: pointer;
  width: 100%;
  transition: all 400ms ease-in-out;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  text-decoration: none;
  color: #676767;
  transition: all 300ms ease-in-out;
}

.screen .left_sidebar .sidebar__navlinks .links:hover {
  box-shadow: 0px 3px 8px #999;
  border-radius: 5px;
  color: #000000;
  background-color: #f6f7f9;
}

.screen .left_sidebar .sidebar__navlinks .links:hover svg {
  color: #26c6da;
}

.screen .left_sidebar .sidebar__navlinks .links h3 {
  font-weight: 300;
  font-size: 0.85rem;
}

.screen .left_sidebar .sidebar__navlinks .links svg {
  margin-right: 1rem;
}

.screen .left_sidebar .sidebar__navlinks .active__navlink {
  box-shadow: 0px 3px 8px #999;
  border-radius: 5px;
  color: #000000;
  background-color: #f6f7f9;
}

.screen .left_sidebar .sidebar__navlinks .active__navlink svg {
  color: #26c6da;
}

.screen .wrapper {
  flex: 1 1;
  max-width: 85%;
  height: 100vh;
  overflow: scroll;
}

.screen .wrapper::-webkit-scrollbar {
  display: none;
}

.admin__home {
  margin: 0 0 2rem;
}

.admin__home .btn__submit-btn {
  background-color: white;
  font-size: 1rem;
  font-weight: 500;
  border: none;
  outline: none;
  border-radius: 4px;
  color: #26c6da;
  padding: 0.7rem 1.2rem;
  cursor: pointer;
  transition: all 400ms ease-in-out;
  margin-top: 1rem;
  border: 1px solid #26c6da;
}

.admin__home .btn__submit-btn:hover {
  box-shadow: 2px 2px 10px #bbb;
  background-color: #26c6da;
  color: white;
}

.admin__home .col {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin: 1rem 0;
}

.admin__home .col .row-4 {
  flex: 0.32 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: white;
  padding: 0.85rem;
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.2);
}

.slink form input {
  width: 100%;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  margin-top: 0.4rem;
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.2);
}

.cimages .carousel_images .cimage__col {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: unset;
}

.cimages .carousel_images .cimage__col .cimage {
  padding: 1rem;
  border: 1px solid rgba(0, 0, 0, 0.2);
  max-width: 31.5%;
  margin-right: 0.5rem;
}

.cimages .carousel_images .cimage__col .cimage:nth-last-child(1) {
  margin-right: 0;
}

.cimages .carousel_images .cimage__col .cimage .img__container {
  max-height: 250px;
  overflow: hidden;
  max-width: 100%;
}

.cimages .carousel_images .cimage__col .cimage .img__container img {
  height: auto;
  width: auto;
}

.cimages .carousel_images .cimage__col .cimage .form__carousel-images {
  width: 100%;
}

.cimages .carousel_images .cimage__col .cimage .form__carousel-images .input {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 1rem 0;
}

.cimages
  .carousel_images
  .cimage__col
  .cimage
  .form__carousel-images
  .input
  label {
  margin-bottom: 0.5rem;
}

.cimages
  .carousel_images
  .cimage__col
  .cimage
  .form__carousel-images
  .input
  textarea {
  width: 100%;
  min-height: 100px;
  padding: 0.5rem 0.5rem;
  font-size: 1rem;
}

.cimages
  .carousel_images
  .cimage__col
  .cimage
  .form__carousel-images
  .input
  input {
  padding: 0.5rem 1rem;
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  font-size: 1rem;
  width: 100%;
}

.banner .banner__image-container {
  max-width: 100%;
  max-height: 400px;
  overflow: scroll;
}

.banner .banner__image-container::-webkit-scrollbar {
  display: none;
}

.banner .banner__image-container img {
  width: auto;
  height: auto;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Roboto", sans-serif;
}

body {
  background-color: #eeeeee;
}

a {
  text-decoration: none;
}

.banner {
  display: flex;
  align-items: center;
  flex-flow: column;
  -webkit-box-align: center;
  max-width: 100%;
  margin: 0;
  overflow: hidden;
  padding: 0;
  position: relative;
  text-align: center;
  width: 100%;
}

.banner picture {
  display: flex;
  width: unset;
  flex-basis: 100%;
  overflow: hidden;
  justify-content: center;
}

.banner img {
  width: auto;
  height: auto;
  max-width: none;
  margin: auto;
}

@media (min-width: 320px) and (max-width: 768px) {
  .banner img {
    height: 20vh;
  }
}

.navbar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0.85rem 2.5%;
  background-color: #002244;
  overflow-x: scroll;
}

.navbar::-webkit-scrollbar {
  display: none;
}

@media screen and (max-width: 768px) {
  .navbar {
    justify-content: flex-end;
  }
}

.navbar .left {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex: 0.5 1;
}

@media screen and (max-width: 1024px) {
  .navbar .left {
    flex: 0.6 1;
  }
}

@media screen and (max-width: 768px) {
  .navbar .left {
    display: none;
  }
}

.navbar .right {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  flex: 0.3 1;
}

@media screen and (max-width: 768px) {
  .navbar .right {
    display: none;
  }
}

.navbar .item {
  padding: 0.5rem;
  text-transform: uppercase;
  color: white;
  font-weight: 600;
  font-size: 0.95rem;
  margin: 0 0.5rem;
  text-decoration: none;
}

@media screen and (max-width: 1024px) {
  .navbar .item {
    font-size: 13px;
    margin: 0 0.2rem;
    padding: 0.2rem;
  }
}

.navbar .humburger {
  display: none;
}

@media screen and (max-width: 768px) {
  .navbar .humburger {
    display: block;
  }
}

.navbar-modal {
  z-index: 1000;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #002244;
  width: 100%;
}

.navbar-modal .close {
  position: absolute;
  top: 4%;
  right: 4%;
  padding: 1rem;
}

.navbar-modal a {
  font-size: 1.2rem;
  color: white;
  text-decoration: none;
  margin: 1rem 0;
}

.dataTableContainer {
  text-align: left;
  box-shadow: 10px 15px 30px grey;
  border-radius: 10px;
  border: 1px solid #ccc;
  width: 80%;
  background-color: #fff;
  padding-bottom: 2rem;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.dataTableContainer .dataTableHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 1rem 2rem;
  border-bottom: 1px solid #ddd;
  border-left: 3px solid blue;
  border-top-left-radius: 10px;
}

.dataTableContainer .dataTable {
  margin: 3rem 1rem 1rem;
  border: 1px solid #ccc;
}

.dataTableContainer .addCompanyButton {
  padding: 0.6rem 1.4rem;
  font-size: 1rem;
  background-color: tomato;
  outline: none;
  border-radius: 10px;
  border: none;
  color: #fff;
}

#data-table-btn-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0.5rem;
}

#data-table-btn-container #data-table-btn {
  padding: 0.3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  font-size: 1.5rem;
}

.rdt_TableHeadRow div:nth-child(1) {
  flex: 0.5 1;
}

.rdt_TableHeadRow div {
  font-size: 1.2rem;
  font-weight: 500;
  text-transform: uppercase;
}

.fVJwWT {
  background-color: #bbb !important;
}

.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
}

.loader {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.1);
}

.iQTtKS {
  display: unset !important;
}

.no-scroll-bar::-webkit-scrollbar {
  display: none;
}

