
.div{
    display: none;
    position: absolute;
    padding: 1rem;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    color: white;
    font-weight: bold;
    font-size: 16px;
    background-color: red;
    

}

.container:hover .div {
  display: block;
  cursor: pointer;
  background: rgba(0, 0, 0, .3);
  cursor: pointer;
}

.container:hover{
  position: relative;
  opacity: 0.5;
}